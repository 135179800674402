import {
  AuthActionsTypes,
  loginUserAction,
  loginUserActionSuccess,
  loginUserErrorAction,
  logoutUserAction,
  registerUserActionSuccess,
  registerUserErrorAction,
  setUserDetailsAction,
} from '@actions/auth.actions';
import { redirectToLogoutPageAction } from '@actions/router.actions';
import { disableTourAction } from '@actions/settings.actions';
import { Injectable } from '@angular/core';
import { AuthApiService } from '@api/auth-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, exhaustMap, map, tap, of, withLatestFrom } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { UserService } from 'src/app/services/user.service';
import { selectRegistrationState } from '../selectors/registration.selectors';
import { IRegistrationState } from '@state/registration.state';
import { IRegistrationPayload } from '@models/registration.model';
import { clearRegistrationObjectAction } from '@actions/registration.actions';
import { Route, Router } from '@angular/router';
import { routeInfixes, routePaths } from 'src/app/app-routing/routes';
import { userNameSelector } from '../selectors/user.selectors';
import { LoadingService } from 'src/app/services/loading.service';

@Injectable()
export class AuthEffects {
  loginUserEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionsTypes.LOGIN_USER),
      exhaustMap((data: any) => {
        return this.authApiService.login(data).pipe(
          map((loginResponse: any) => {
            console.log('loginResponse', loginResponse);
            if (loginResponse.errors) {
              this.notificationService.showToast(
                this.translate.instant(
                  this.translate.instant(
                    'AUTH.REGISTER.ERROR_MESSAGES.CREDENTIALS'
                  )
                ),
                'toast-alert',
                2000
              );

              return loginUserErrorAction();
            } else if (loginResponse.data) {
              return loginUserActionSuccess(loginResponse);
            }
            this.notificationService.showToast(
              this.translate.instant('AUTH.LOGIN.ERROR_MESSAGES.CREDENTIALS'),
              'toast-alert',
              2000
            );
            this.loadingService.hideLoading();

            return loginUserErrorAction();
          }),

          catchError((error) => {
            this.notificationService.showToast(
              this.translate.instant('AUTH.LOGIN.ERROR_MESSAGES.CREDENTIALS'),
              'toast-alert',
              2000
            );
            this.loadingService.hideLoading();

            return of(loginUserErrorAction());
          })
        );
      })
    )
  );

  changePasswordEffects$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionsTypes.CHANGE_PASSWORD),
        withLatestFrom(this.store.select(userNameSelector)),
        exhaustMap(
          ([{ password }, username]: [{ password: string }, string]) => {
            return this.authApiService.changePassword(username, password).pipe(
              map((registerResponse) => {
                this.notificationService.showToast(
                  this.translate.instant('PROFILE.RESET_PASSWORD.CONFIRM'),
                  'toast-alert',
                  2000
                );
              }),

              catchError((error) => {
                console.log('ERROR', error);
                this.notificationService.showToast(
                  error.errors[0],
                  'toast-alert',
                  2000
                );
                return of(registerUserErrorAction());
              })
            );
          }
        )
      ),
    { dispatch: false }
  );

  registerUserEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionsTypes.REGISTER_USER),
      withLatestFrom(this.store.select(selectRegistrationState)),
      exhaustMap(([_, registrationData]: [any, IRegistrationPayload]) => {
        return this.authApiService.register(registrationData).pipe(
          map((registerResponse) => {
            this.notificationService.showToast(
              this.translate.instant(
                'AUTH.REGISTER.SUCCESS_MESSAGES.CREDENTIALS'
              ),
              'toast-alert',
              2000
            );
            return registerUserActionSuccess(registerResponse);
          }),

          catchError((error) => {
            console.log('ERROR', error);
            this.notificationService.showToast(
              error.errors[0],
              'toast-alert',
              2000
            );
            return of(registerUserErrorAction());
          })
        );
      })
    )
  );

  // setJwt
  registerSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionsTypes.REGISTER_USER_SUCCESS),
      map(() => {
        this.router.navigate([routePaths.auth.login]);
        return clearRegistrationObjectAction();
      })
    )
  );

  // setJwt
  loginSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionsTypes.LOGIN_USER_SUCCESS),
        tap((response: { data: { jwt: { access_token: string } } }) => {
          this.authService.login(response.data.jwt.access_token);
          this.store.dispatch(setUserDetailsAction());
        })
      ),
    { dispatch: false }
  );

  setUserDetailsEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionsTypes.SET_USER_DETAILS),
        tap((data) => {})
      ),
    { dispatch: false }
  );

  logoutUserEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionsTypes.LOGOUT_USER),
        tap(() => {
          // localStorage.clear();
          this.authService.logout();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private authApiService: AuthApiService,
    private actions$: Actions,
    private authService: AuthenticationService,
    private userService: UserService,
    private notificationService: NotificationsService,
    private store: Store,
    private translate: TranslateService,
    private router: Router,
    private loadingService: LoadingService
  ) {}
}
