import {
  clearQuestsAction,
  retrieveProgressActionSuccess,
  setQuestsProgressAction,
} from '@actions/progress.actions';
import { setActiveLanguageAction } from '@actions/settings.actions';
import { createReducer, on } from '@ngrx/store';
import { IProgressState } from '@state/progress.state';

export const progressInitialState: IProgressState = {
  quests: { 1: { questId: 1, timesPlayed: 0 } },
};

export const progressReducer = createReducer(
  progressInitialState,

  on(clearQuestsAction, () => {
    return progressInitialState;
  }),

  on(setQuestsProgressAction, (state, { questsProgress }) => {
    return {
      ...state,
      quests: { ...state.quests, ...questsProgress },
    };
  }),
  on(retrieveProgressActionSuccess, (state, { progressData, replace }) => {
    if (replace) {
      return {
        ...state,
        quests: progressData.quests,
      };
    }
    return state;
  })
);
